export const SET_USER = "SET_USER"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_USER_AND_CUSTOMER = "SET_USER_AND_CUSTOMER"
export const LOGOUT = "LOGOUT"

export const initialMemberState = {
  user: null,
  customer: null,
  subscription: null,
}

const memberReducer = (state, action) => {
  const { type, payload } = action
  
  switch (type) {
    case SET_USER:
      return { ...state, user: payload.user }
    case SET_CUSTOMER:
      return { ...state, customer: payload.customer, subscription: payload.subscription }
    case SET_USER_AND_CUSTOMER:
      return { ...state, user: payload.user, customer: payload.customer, subscription: payload.subscription}
    case LOGOUT:
      return { user: null, customer: null, subscription: null }
    default:
      throw new Error()
  }
}

export default memberReducer
