import React, { useEffect } from 'react'
import netlifyIdentity from 'netlify-identity-widget'

const Netlify = ({ children }) => {

  useEffect(() => {
    netlifyIdentity.init()

  }, [])
  
  return (
    <>
      {children}
    </>
  )
}

export default Netlify
