import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

import Netlify from './src/netlify'
import MemberProvider from './src/member/memberProvider'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC)

export const wrapRootElement = ({ element }) => {
  return (
    <Netlify>
      <MemberProvider>
          <Elements stripe={stripePromise}>
            {element}
          </Elements>
      </MemberProvider>
    </Netlify>
  )
}

/*
* ANY UPDATES TO THIS FILE ALSO NEED TO BE MADE TO gatsby-ssr.js
*/
