const getMember = async (email) => {
  // TODO: Replace with async await
  return await fetch(`/.netlify/functions/getCustomerAndSubscriptions`, {
    method: 'POST',
    body: JSON.stringify({
      email
    }),
  })
  .then((result) => {
    if (result.ok) {
      return result.json()
    }
  }).then((data) => {
    return data
  }).catch((error) => {
    console.error('Error: ', error)
    return new Error(error)
  })
}

export default getMember
