import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import netlifyIdentity from 'netlify-identity-widget'

import MemberReducer, { initialMemberState } from './memberReducer'

import { SET_USER_AND_CUSTOMER, LOGOUT } from '../member/memberReducer'
import getMemberAndSubscriptions from '../member/getMemberAndSubscriptions'
import MemberStateContext from './memberStateContext'
import MemberDispatchContext from './memberDispatchContext'

const MemberProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MemberReducer, initialMemberState)

  useEffect(() => {
    const getCustomer = async (user) => {
      try {
        const { customer, subscription } = await getMemberAndSubscriptions(user.email)

        dispatch({
          type: SET_USER_AND_CUSTOMER,
          payload: {
            user: user,
            customer: customer,
            subscription: subscription,
          },
        })
      } catch (error) {
        console.error(error)
      }
    }

    const user = netlifyIdentity.currentUser()

    if (user) {
      getCustomer(user)
    }

    // On LOGOUT
    netlifyIdentity.on('logout', (user) => {
      dispatch({ type: LOGOUT })
    })

    // On LOGIN
    netlifyIdentity.on('login', (user) => { 
      getCustomer(user)
  
      netlifyIdentity.close()
    })

  }, [])

  return (
    <MemberDispatchContext.Provider value={dispatch}>
      <MemberStateContext.Provider value={state}>
        {children}
      </MemberStateContext.Provider>
    </MemberDispatchContext.Provider>
  )
}

MemberProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default MemberProvider
